import { useMainStore } from '~/stores/MainStore'
import type { ImageType } from '~~/types'
export const usePageHead = async ({
  title,
  seo,
  titleImage,
  url,
}: {
  title: string
  seo: any
  titleImage?: ImageType
  url?: string
}) => {

  const titleString = unref(title)
  if (!titleString) {
    return
  }

  const mainStore = await useMainStore()
  
  // create og image url
  const ogImage = seo?.img?.asset
    ? seo?.img?.asset
    : titleImage?.asset
      ? titleImage.asset
      : mainStore.siteSettings?.seo?.ogImage?.asset
  const { $urlFor } = useNuxtApp()
  const ogImageUrl = ogImage ? $urlFor(ogImage).width(1200).format("jpg").url() : ''
  

  // create long title
  // const longTitleString = `${titleString} – ${mainStore.siteOptions?.name}`
  const longTitleString = `${titleString}`

  useSeoMeta({
    ogTitle: () => `${titleString}`,
    ogUrl: url,
    description: () => seo?.description,
    ogDescription: () => seo?.description,
    ogImage: () => ogImageUrl,
    ogImageAlt: () => seo?.img?.alt ?? '',
    ogImageWidth: () => (ogImageUrl ? '1200' : ''),
    ogImageHeight: () =>
      Math.floor(1200 / (ogImage?.data?.dimensions?.aspectRatio ?? 1.5)),
    ogImageType: () => ogImage?.mimeType ?? 'image/jpeg',
    twitterCard: seo?.description,
    twitterTitle: () => longTitleString,
    twitterDescription: () => seo?.description,
    twitterImage: () => ogImageUrl,
  })
}
